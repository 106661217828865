/* @import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap"); */

@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700&display=swap");
html {
  scroll-behavior: smooth !important;
}

.animated-text {
  position: relative;
  overflow: hidden;
  background: linear-gradient(90deg, #000, #fff, #000);
  background-repeat: no-repeat;
  background-size: 80%;
  animation: animate 2s linear infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
}

@keyframes animate {
  0% {
    background-position: -500%;
  }
  100% {
    background-position: 500%;
  }
}
input::-ms-reveal,
input::-ms-clear {
  display: none;
}

::-webkit-scrollbar {
  background-color: #4488ff44;
  width: 5px;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb {
  background-color: #4488ff;
  border-radius: 5px;
}
